import { Contact } from "../components";

const ContactUs = () => {
  return (
    <div>
      <Contact />
    </div>
  );
};
export default ContactUs;
