import { RegisterForm } from "../components";

const Register = () => {
  return (
    <>
      <RegisterForm />
    </>
  );
};
export default Register;
